import React from "react";
import { H3, H4 } from "../../../elements/index";

/*
    TODO: allow edit user settings/preferences

*/
export const UserSettings = () => {
    return (
        <>
            <H3>User Settings</H3>
            <H4>COMING SOON</H4>
        </>
    );
};
