import React from "react";
import styled from "styled-components";

// Styled components
const H3 = styled.h3`
    margin-top: 15px;
`;

/*
    SUMMARY:
        Header for Recipe Detail Page
    PARAMS: 
    
*/

export const Header = () => {
    return (
        <>
            <H3>Ingredients</H3>
        </>
    );
};
